import Splide from '@splidejs/splide'

export default () => {
  return {
    init() {
      new Splide(this.$refs.splide, {
        type: 'loop',
        pagination: true,
        perPage: 1,
        mediaQuery: 'min',
        arrows:false,
        breakpoints: {
                640: {
                    arrows: true,
                },
        },
      }).mount()
    },
  }
}
